//AuthStyles.scss
.bg_color_white {
  background-color: white;
}

.heading_register {
  font-weight: 900;
  font-size: 3rem;
  text-align: center;
}

.sub_head_reg {
  font-size: 1.5rem;
  margin: 1.25rem;
  text-align: center;
}

.__input_auth {
  background-color: #f5f8fa;
  border: 1px solid #f5f8fa;
  border-radius: 0.625rem;
  box-shadow: none;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 2.6em;
  padding: 5px 14px 5px 30px;
  outline: none;
  width: 100%;
}

.forgot_password_main {
  margin-top: 16px !important;
  text-align: right;
  .forgot_password_link {
    color: #3699ff !important;
    cursor: pointer;
    text-decoration: none;
  }
}

.login_button {
  background-color: #3699ff;
  border: 0;
  border-radius: 30px;
  padding: 10px 30px;
  width: fit-content;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  .login_font {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
  }
}

.divide_or {
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  .__divider {
    flex: 1;
    border-right: 1px solid #ccc;
    position: relative;
    left: -9px;
  }
}

.link_tag_login {
  color: #3699ff !important;
  cursor: pointer;
}

.auth-form-container {
  display: flex !important;
  flex-direction: row !important;
}

@media only screen and (max-width: 762px) {
  .mainContainer {
    flex-direction: column-reverse;
  }
  .or_class {
    color: #80808f;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .divide_or {
    flex-direction: row;
  }
  .__divider {
    display: none;
  }
  .login_button {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .login_button .login_font {
    font-size: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .heading_register {
    font-weight: 800;
    font-size: 2.3rem;
    text-align: center;
    margin-top: 2rem;
  }
  .sub_head_reg {
    font-size: 1.25rem;
    margin: 1rem;
    text-align: center;
  }
  .mainContainer {
    padding: 1rem 2rem;
  }
}
