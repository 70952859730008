//
// Bootstrap Select
//

.mySelect__value-container{
    height: calc(1.5em + 1.3rem);
   
    &:hover {
        cursor: pointer;
    };
    
}
