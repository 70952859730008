.rdrDateRangePickerWrapper {
    border: 1px solid #eae8e8;
    position: absolute;
    margin-left: -170px;
    z-index: 1;
}
.rdrDateRangePickerWrapperCustom{
    border: 1px solid #eae8e8;
    position: absolute;
    margin-left: auto !important;
    z-index: 9;  
}
.google-places-autocomplete__input {
    display: block;
    width: 100% !important;
    height: calc(1em + 1.3rem + 2px);
    padding: 0.65rem 1rem !important;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0px !important;
    line-height: 1.5;
    color: #464e5f;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e5eaee !important;
    border-radius: 0.42rem;
    box-shadow: none !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label::after {
    position: fixed !important;
}
.custom-control-label::before {
    position: fixed !important;
}
.form-group {
    margin-bottom: 0px;
}
.timeline.timeline-5:before {
    position: relative !important;
}
.add_new_item {
    padding: 0;
    margin-bottom: 0;
    background: #ccc;
}
.add_new_item li {
    list-style-type: none;
    padding: 5px 10px;
    border-bottom: 1px solid #fff;
}

.main_select {
    position: relative;
    .add_new_item {
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translatey(100%);
        z-index: 1;
        display:none
        // opacity: 0;
    }
    ul.select_item {
        opacity: 1;
        display:block
    }
}

.item_field {
    opacity: 0;
    margin: -10em;
}
.item_data {
    display: none;
}
.display_field {
    display: block;
}
.rbc-month-view{
 height:100vh !important
}
.modal-70w{
    width:100% !important
}


//css for slider 
//css added for slider 

.sliderWrapper {
    position: relative;
    width: 75px;
    border: 2px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    height: 32px;
    margin-top: 4px;
    box-shadow: 0px 1px 2px;
    // position: relative;
    // width: 75px;
    // border: 1px solid #ccc;
    // margin-top: 4px;
    // border-radius: 6px;
    // overflow: hidden;
    // height: 32px;
    // box-shadow: 0px 1px 2px;
    // cursor: pointer;
  }
  
  input[type=checkbox]:checked + label .sliderOptions {
    left: -45px;
    /* width of the yes/no width plus the padding */
  }
  
  .sliderOptions {
    position: relative;
    left: 0;
    white-space: nowrap;
    -ms-transition: left 0.2s ease-in-out;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
  }
  
  .sliderOptionSelector{
    display: inline-block;
    width: 25px;
    background: #fff;
  }
  
  .sliderOptionNo{
    display: inline-block;
    width: 60px;
    padding:3px 5px;
    padding-left: 15px;
    text-align: center;


    // display: inline-block;
    // width: 60px;
    // padding: 3px 5px;
    // padding-left: 15px;
    // text-align: center;
  }
  
  
  .sliderOptionYes {
    display: inline-block;
    width: 50px;
    padding: 3px 5px;
    text-align: center;
  }
  
  .sliderOptionYes {
    // color: #aaa;
    // background: #ddd;
    color: #333;
    background: #ddd;
  }
  
  .sliderOptionNo {
    color: #fff;
   
    // background: #0a0;


    // color: #fff;
    background: #3699FF;
  }
  
  #checkbox {
    display: none;
  }