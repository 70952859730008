// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// .nav-link:focus {
//     outline: none;
// }

// radio styling
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.card_like_button {
  border-left: 5px solid black !important;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    border-left: 5px solid $primary !important;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

// input:checked + .slider:before {
//   -webkit-transform: translateX(26px);
//   -ms-transform: translateX(26px);
//   transform: translateX(26px);
// }

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

// .slider.round:before {
//   border-radius: 50%;
// }

.switch input:empty ~ span:after {
  height: 21px;
  width: 21px;
  // top: 3px;
  // bottom: 3px;
  // margin-left: 3px;
  // font-size: 0.65em;
  // text-align: center;
  // vertical-align: middle;
}

$theme-colors: (
  "primary": $success,
);
//#17c191,

/* Lock button styles to prevent drag conflicts */
.lock-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 98 !important; /* Lower z-index that won't interfere with modals/toasts */
  cursor: pointer !important;
  pointer-events: auto !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* Style the lock icon to make it more visible */
  span {
    pointer-events: none;
    i {
      font-size: 18px;
      pointer-events: none;
    }
  }
}

/* Make sure lock button is excluded from drag operations */
.react-grid-item .lock-button {
  pointer-events: auto !important;
  cursor: pointer !important;
}

/* Fix for dropdown menus in tables */
.table-responsive {
  overflow-x: auto !important;
  overflow-y: visible !important; /* This ensures dropdowns are visible */
}

/* Fix for card containers with dropdowns */
.card {
  overflow: visible !important; /* Ensure dropdowns are visible */
  contain: none !important; /* Override any 'contain' property */
}
