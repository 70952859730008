//
// Toastr
//




// Base
.toast {
    transition: $transition;
    background-position: 15px center #{'/*rtl:calc(100% - 15px) center*/'} !important;
	border: 0;
    background-color: $gray-100;
    color: $dark-75;
    box-shadow: $box-shadow !important;

    &:hover {
        transition: $transition;
        box-shadow: $box-shadow !important;
        opacity: 1;
    }

	.toast-close-button {
   		outline: none !important;
	    font-size: 0;

        &:before {
            @include ki(get($ki-types, close));
        	font-size: 0.9rem;
        }
	}

	// Title
	.toast-title {
		font-size: 1.1rem;
		font-weight: 500;
		margin-bottom: 0.25rem;
	}

	// Message
	.toast-message {
		font-size: 1rem;
        font-weight: 500;
	}

    // States
    &.toast-success {
        // background-color: $success;
        color: $success
        icon
    }

    &.toast-info {
        // background-color: $info;
        color: $info-inverse;
    }

    &.toast-warning {
        // background-color: $warning;
        color: $warning-inverse;
    }

    &.toast-error {
        // background-color: $danger;
        color: $danger-inverse;
    }
}

.Toastify__toast-body {
    font-weight: 550;
}

// .Toastify__toast-icon--success {
//     color: $success;
// }

// :root {
//     //Used only for colored theme
//     --toastify-icon-color-info: $info;
//     --toastify-icon-color-success: $success;
//     --toastify-icon-color-warning: $warning;
//     --toastify-icon-color-error: $error;
// }

// .Toastify-icon-color-info: $info
//   --toastify-icon-color-success: var(--toastify-color-success);
//   --toastify-icon-color-warning: var(--toastify-color-warning);
//   --toastify-icon-color-error: var(--toastify-color-error);

// .Toastify__toast--success {
//     background: $success !important;
//   }

// .Toastify__toast--danger {
// background: $danger !important;
// }

// .Toastify__toast--warning {
//     background: $warning !important;
// }
