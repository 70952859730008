/* 
 * Partner Login Form Styles
 */

.partner-login-container {
  max-width: 500px;
  margin: 3rem auto;
  padding: 0 1rem;
  will-change: transform;
}

.card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  contain: content;
}

.card-body {
  padding: 2rem;
}

/* Header styling */
.partner-login-header {
  margin-bottom: 2rem;
  text-align: center;
}

.partner-login-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212121;
  margin-bottom: 0.5rem;
}

/* Logo styling */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.sendatradie-logo {
  max-width: 200px;
  height: auto;
}

/* Form styling */
.login-form {
  margin-top: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  border-color: #e4e6ef;
  border-radius: 0.25rem;
}

.form-control:focus {
  border-color: #3699ff;
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #f64e60;
}

.invalid-feedback {
  color: #f64e60;
  font-size: 0.85rem;
  display: block;
  margin-top: 0.25rem;
}

/* Login button */
.btn-primary {
  background-color: #3699ff;
  border-color: #3699ff;
  font-weight: 500;
  padding: 0.65rem 1rem;
  transition: background-color 0.15s ease-in-out;
}

.btn-primary:hover {
  background-color: #187de4;
  border-color: #187de4;
}

.btn-lg {
  padding: 0.85rem 1.5rem;
  font-size: 1rem;
}

.btn-block {
  width: 100%;
}

/* Links */
.forgot-password-link, .signup-link {
  color: #3699ff;
  text-decoration: none;
  font-size: 0.9rem;
}

.forgot-password-link:hover, .signup-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-body {
    padding: 1.5rem;
  }
  
  .partner-login-container {
    margin: 2rem auto;
  }
}