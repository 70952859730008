@import 'bootstrap-icons/font/bootstrap-icons.css';

.input_css{
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #464E5F;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E5EAEE;
    border-radius: 0.42rem;
    box-shadow: none;
}