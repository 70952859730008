.notification_container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  .notification_icon {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification_content {
    flex: 0.8;
    .notification_time {
      color: #b5b5c3;
      font-weight: 600;
    }
  }
}
