//css added for slider 

.sliderWrapper {
    position: relative;
    width: 75px;
    border: 2px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    height: 32px;
    margin-top: 4px;
    box-shadow: 0px 1px 2px;
    // position: relative;
    // width: 75px;
    // border: 1px solid #ccc;
    // margin-top: 4px;
    // border-radius: 6px;
    // overflow: hidden;
    // height: 32px;
    // box-shadow: 0px 1px 2px;
    // cursor: pointer;
  }
  
  input[type=checkbox]:checked + label .sliderOptions {
    left: -45px;
    /* width of the yes/no width plus the padding */
  }
  
  .sliderOptions {
    position: relative;
    left: 0;
    white-space: nowrap;
    -ms-transition: left 0.2s ease-in-out;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
  }
  
  .sliderOptionSelector{
    display: inline-block;
    width: 25px;
    background: #fff;
  }
  
  .sliderOptionNo{
    display: inline-block;
    width: 60px;
    padding:3px 5px;
    padding-left: 15px;
    text-align: center;


    // display: inline-block;
    // width: 60px;
    // padding: 3px 5px;
    // padding-left: 15px;
    // text-align: center;
  }
  
  
  .sliderOptionYes {
    display: inline-block;
    width: 50px;
    padding: 3px 5px;
    text-align: center;
  }
  
  .sliderOptionYes {
    // color: #aaa;
    // background: #ddd;
    color: #333;
    background: #ddd;
  }
  
  .sliderOptionNo {
    color: #fff;
   
    // background: #0a0;


    // color: #fff;
    background: #3699FF;
  }
  
  #checkbox {
    display: none;
  }