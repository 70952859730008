/*
 * Partner Dashboard Styles - Simplified version
 */

/* Ensure full height for root elements */
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eef0f8;
}

.partner-dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: #eef0f8;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}

/* Loading state */
.partner-dashboard-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Header styles */
.partner-dashboard-header {
  background-color: #fff;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 140px;
}

.partner-logo {
  max-width: 100%;
  height: auto;
}

.header-title h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #3f4254;
}

.header-actions {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-controls {
  display: flex;
  gap: 8px;
}

.welcome-message {
  font-weight: 500;
  color: #464e5f;
  white-space: nowrap;
}

.header-btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s ease;
  box-shadow: none;
}

.header-btn-primary {
  color: #fff;
  background-color: #3699ff;
  border-color: #3699ff;
}

.header-btn-primary:hover {
  background-color: #187de4;
  border-color: #187de4;
}

.header-btn-secondary {
  color: #3f4254;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.header-btn-secondary:hover {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.header-btn-light {
  color: #7e8299;
  background-color: transparent;
  border: 1px solid #e4e6ef;
}

.header-btn-light:hover {
  color: #3699ff;
  background-color: #f3f6f9;
  border-color: #e4e6ef;
}

/* Main content styles */
.partner-dashboard-content {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Big number display */
.big-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #3699ff;
  margin: 0.5rem 0;
}

/* Cards styles */
.card {
  border: none;
  border-radius: 0.475rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  margin-bottom: 1.5rem;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #464e5f;
  margin-bottom: 1.25rem;
}

.welcome-card {
  background-color: #f1faff;
  border-left: 4px solid #3699ff;
}

.welcome-card .card-title {
  color: #3699ff;
}

.stats-card .card-text {
  font-size: 0.95rem;
}

/* Table styles */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
  background-color: #f8f9fa;
  font-weight: 600;
}

.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #e9ecef;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Footer styles */
.partner-dashboard-footer {
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-top: 1px solid #ebedf3;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.copyright {
  margin: 0;
  color: #7e8299;
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
}

.footer-links a {
  color: #7e8299;
  font-size: 0.9rem;
  text-decoration: none;
}

.footer-links a:hover {
  color: #3699ff;
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .partner-dashboard-header {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    text-align: center;
  }

  .header-logo {
    margin-bottom: 0.5rem;
  }

  .partner-dashboard-content {
    padding: 1.5rem 1rem;
  }

  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .big-number {
    font-size: 2rem;
  }

  .user-info {
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
  }

  .user-controls {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .header-btn {
    width: auto;
    padding: 0.4rem 0.7rem;
    font-size: 0.8rem;
  }

  .welcome-message {
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    gap: 0.5rem;
  }
}
