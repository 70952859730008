//
// Base
//

// Body
body {
	background: $page-bg;
}


// Desktop Mode
@include media-breakpoint-up(lg) {
	span.svg-icon.svg-icon-sm.svg-icon-primary.ml-1.svg-icon-sort::before{
		content: "\A";
		white-space: pre;
	}
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
		}

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}

//css for maps page
@media (max-width:992.98px){
	.custom-map{
		display: none;
		// padding: 0 !important;
	}
	&.showMap{
		display: block;
		// padding: 0 !important;
	}
	.map-filters{
		display: block;
	} 
	&.new-map-filters{
		display: none;
	}

	.custom-map .buttonMap{
		display: block;
	}
}
.buttonMap{

	display: none;
}

.pin {
	width: 30px;
	height: 30px;
	border-radius: 50% 50% 50% 0;
	// background:#ff7f50;
	position: absolute;
	// -webkit-transform: rotate(-45deg);
	// -moz-transform: rotate(-45deg);
	// -o-transform: rotate(-45deg);
	// -ms-transform: rotate(-45deg);
	// transform: rotate(-45deg);
	left: 50%;
	top: 50%;
	margin: -15px 0 0 -15px;
	-webkit-animation-name: bounce;
	-moz-animation-name: bounce;
	-o-animation-name: bounce;
	-ms-animation-name: bounce;
	animation-name: bounce;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-o-animation-duration: 1s;
	-ms-animation-duration: 1s;
	animation-duration: 1s;
	// z-index: 20;
	cursor:pointer
  }
  .pin::after {
	content: '';
	width: 6px;
	height: 6px;
	margin: 5px 0 0 -11px;
	background: #ffffff;
	position: absolute;
	border-radius: 50%;
	// z-index: 18;
  }
  .pulse {
	background: rgba(0,0,0,0.2);
	border-radius: 50%;
	height: 14px;
	width: 14px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: 11px 0px 0px -12px;
	-webkit-transform: rotateX(55deg);
	-moz-transform: rotateX(55deg);
	-o-transform: rotateX(55deg);
	-ms-transform: rotateX(55deg);
	transform: rotateX(55deg);
	z-index: -2;
  }
  .pulse:after {
	content: "";
	border-radius: 50%;
	height: 40px;
	width: 40px;
	position: absolute;
	margin: -13px 0 0 -13px;
	-webkit-animation: pulsate 1s ease-out;
	-moz-animation: pulsate 1s ease-out;
	-o-animation: pulsate 1s ease-out;
	-ms-animation: pulsate 1s ease-out;
	animation: pulsate 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-webkit-box-shadow: 0 0 1px 2px #89849b;
	box-shadow: 0 0 1px 2px #89849b;
	-webkit-animation-delay: 1.1s;
	-moz-animation-delay: 1.1s;
	-o-animation-delay: 1.1s;
	-ms-animation-delay: 1.1s;
	animation-delay: 1.1s;
  }
  
.list-view-maps{
	max-height: 300px;
	overflow: hidden;
	overflow-y: scroll,
}
.map-view-maps{
	height: "100vh"; 
	width: "100%"
}

  //css end for maps page