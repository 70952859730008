/* 
 * Partner Signup Form Styles - Optimized for performance
 */

.partner-signup-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
  will-change: transform; /* Optimizes for GPU acceleration */
}

.card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); /* Lighter shadow */
  border-radius: 8px;
  overflow: hidden;
  contain: content; /* Improves performance by isolating content */
}

.card-body {
  padding: 1.5rem;
}

/* Header styling - simplified */
.partner-signup-header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.partner-signup-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212121;
  margin-bottom: 0.5rem;
}

/* Logo styling */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.sendatradie-logo {
  max-width: 200px;
  height: auto;
}

/* Section styling */
.section-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #464e5f;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 0.5rem;
}

/* Partnership type selection */
.partnership-type-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 1rem;
}

.partnership-type-button {
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 4px;
  transition: none; /* Remove transition for better performance */
}

.partnership-type-button.active {
  background-color: #3699ff !important;
  color: white;
}

.form-section {
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

/* Phone input styling removed - using regular input field */

/* Password strength meter - simplified */
.strength-meter {
  display: flex;
}

.strength-segment {
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 1rem;
}

/* Predefine colors instead of using dynamic class names */
.strength-segment.bg-danger {
  background-color: #f64e60 !important;
}

.strength-segment.bg-warning {
  background-color: #ffa800 !important;
}

.strength-segment.bg-success {
  background-color: #1bc5bd !important;
}

.strength-segment.bg-secondary {
  background-color: #e4e6ef !important;
}

/* Form inputs and validation - simplified */
.form-control {
  border-color: #e4e6ef;
  padding: 0.65rem 1rem;
  height: calc(1.5em + 1.3rem + 2px);
  transition: none; /* Remove transition for better performance */
}

.form-control:focus {
  border-color: #3699ff;
  box-shadow: none; /* Remove box-shadow for better performance */
}

.form-control.is-invalid {
  border-color: #f64e60;
}

.invalid-feedback {
  color: #f64e60;
  font-size: 0.85rem;
  display: block;
  margin-top: 0.25rem;
}

/* Textarea styling */
textarea.form-control {
  min-height: 100px;
  height: auto;
}

/* Button styling - simplified */
.btn-primary {
  background-color: #3699ff;
  border-color: #3699ff;
  font-weight: 500;
  padding: 0.65rem 1rem;
  transition: none; /* Remove transition for better performance */
}

.btn-primary:hover {
  background-color: #187de4;
  border-color: #187de4;
}

.btn-lg {
  padding: 0.85rem 1.5rem;
  font-size: 1rem;
}

.btn-block {
  width: 100%;
}

/* Success page styling - simplified */
.success-icon {
  margin-bottom: 1.5rem;
}

.success-icon i {
  font-size: 3rem;
  color: #1bc5bd;
}

/* Responsive adjustments - simplified */
@media (max-width: 768px) {
  .card-body {
    padding: 1rem;
  }

  .partnership-type-buttons {
    flex-direction: column;
  }
  
  .partnership-type-buttons .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
