.input-field {
    margin-top: 1.5rem; /* adjust as needed */
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #D3D3D3;  /* light grey color, adjust as needed */
    color: whitesmoke;
    text-align: center;
    border-radius: 10px;
}

.dialer-container {
    font-family: 'Roboto', sans-serif;
    max-width: 400px;
    margin: auto;
  }
  
  .number-font {
    font-size: 50px;
  }
  
  .btn-round {
    width: 75px;
    height: 75px;
    border-radius: 50% !important;
    padding: 0;
    text-align: center;
    font-size: 3rem;
    line-height: 75px;
  }
  
  .btn i {
    vertical-align: middle;
    font-size: 3rem;
  }
  
  
  .display-text {
    font-size: 1.5rem;
    text-align: center;
    border: none; /* Removes default input field border */
    outline: none; /* Removes focus outline */
    background: white; /* Makes background transparent */
    cursor: default; /* Removes text input cursor shape */
}
  
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color: green ;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: green;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
