.progress_icon {
    background-color: white;
    width: 30px;
    text-align: center;
    border-radius: 50%;
  }

  .sendie_icon {
    background-color: white;
    max-width: 100px;
    text-align: center;
    border-radius: 50%;
  }

  .progress_icon:hover {
    background-color: gainsboro;
  }
  