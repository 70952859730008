// LoginPage.scss
@import "../AuthForm.scss";

.term_cond_sent {
  margin-top: 3rem;
}

.Already_acc {
  margin-top: 2rem;
}

.client_portal_ip {
  margin-top: 14px;
}

.flex_05 {
  flex: 0.5;
}
