/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-container {
  background-color: #fff;
  border-radius: 0.475rem;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ebedf3;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #3f4254;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #b5b5c3;
  cursor: pointer;
  transition: color 0.15s ease;
}

.close-button:hover {
  color: #3699ff;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  padding: 1.5rem 0 0.5rem 0;
  border-top: none;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #464e5f;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464e5f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out;
}

.form-control:focus {
  color: #464e5f;
  background-color: #fff;
  border-color: #69abff;
  outline: 0;
  box-shadow: none;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.475rem;
}

.alert-danger {
  color: #f64e60;
  background-color: #ffe2e5;
  border-color: #ffccd0;
}

.alert-success {
  color: #1bc5bd;
  background-color: #c9f7f5;
  border-color: #9fe7e3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #3699ff;
  border-color: #3699ff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1a86ff;
  border-color: #0d7df7;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #3699ff;
  border-color: #3699ff;
  opacity: 0.65;
}

.btn-secondary {
  color: #181c32;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}

.btn-secondary:hover {
  color: #181c32;
  background-color: #d0d3e0;
  border-color: #c9cdd8;
}

.btn-secondary:disabled {
  color: #181c32;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  opacity: 0.65;
}

/* Responsive styles */
@media (max-width: 576px) {
  .modal-container {
    max-width: 90%;
  }
  
  .modal-footer {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .modal-footer button {
    width: 100%;
  }
}