//
// Aside
//

.aside {
  width: get($aside-config, base, width);
  background-color: #fafafa !important;
  // Aside Menu
  .aside-menu {
    @include perfect-scrollbar-ver-offset(4px);
    background-color: #fafafa;
  }
}
div#kt_brand {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
#kt_aside_menu_wrapper {
  border-right: 1px solid #ddd;
}
span.menu-text {
  font-size: 16px !important;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: #000 !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  color: #000 !important;
  background-color: #ddd !important;
}

// Target all paths inside SVGs on hover
.aside-menu .menu-nav > .menu-item:hover .menu-link svg path {
  fill: #3699ff !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #dddddd !important;
}
.menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #dddddd !important;
}
// Desktop Mode
@include media-breakpoint-up(lg) {
  .aside {
    // Fixed Aside Mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: get($aside-config, base, zindex);
    }

    // Static Aside Mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Fixed Header & Static Aside Modes
    .header-fixed.aside-static & {
      padding-top: get($header-config, desktop, default, height);
    }

    // Minimized Aside Mode
    .aside-minimize & {
      width: get($aside-config, base, minimized-width);
    }

    // Scrollbar Width
    @include perfect-scrollbar-ver-size(
      get($aside-config, base, scrollbar-width)
    );
  }

  // Fixed Aside & Minimize Aside Hover Modes
  .aside-fixed.aside-minimize-hover {
    .aside {
      width: get($aside-config, base, width);
    }

    .wrapper {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Fixed Aside, Fixed Header & Minimize Aside Hover Modes
  .aside-fixed.header-fixed.aside-minimize-hover {
    .header {
      left: get($aside-config, base, minimized-width);
    }
  }
}

// Build Aside Menu
@include menu-ver-build-layout($aside-menu-config);

// Aside Offcanvas Panel For Mobile Mode
@include offcanvas-build(
  aside,
  tablet-and-mobile,
  get($aside-config, offcanvas-mobile)
);
