//
// CKEditor
//


// Base
.ck-target{
    display: none;
}

.ck-toolbar{
    @include border-radius($border-radius !important);
}

.ck-content{
    min-height: 200px;
    @include border-radius($border-radius !important);

    &.ck-focused{
        border-color:  $primary !important;
        box-shadow: none !important;
    }
}

// CKEditor Classic & Document
.ck-editor{
    .ck-toolbar{
        @include border-top-radius($border-radius !important);
        @include border-bottom-radius(0 !important);
    }
    .ck-content{
        @include border-bottom-radius($border-radius !important);
        @include border-top-radius(0 !important);
    }
}

// .ck.ck-editor__editable_inline {
//     height: 0px;
//    flex: 1 1 auto;
//  }

// .ck-editor__editable_inline {
//     min-height: 500px;
//  }

.ck-editor__editable
{
   min-height: 239px !important;
   max-height: 500px !important;
}

// CKEditor Inline / Balloon / Balloon Block
.ck-body {
    .ck-balloon-panel{
        .ck-content{
            min-height: 200px;
            border-color: transparent !important;

            &.ck-focused{
                border-color: $primary !important;
            }
        }

        &.ck-toolbar-container,
        .ck-toolbar {
            @include border-radius($border-radius !important);
        }
    }
}
