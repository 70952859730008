$event-bg-color: #d0eaff;  // Soft light blue
$event-border-color: #91c8ff; // Slightly darker blue for border
$event-hover-color: #a3d4ff; // Slightly darker blue for hover

$rbc-event-not-set-bg-color: #ffcccb;  // Soft red for not set
$rbc-event-not-set-border-color: #a65050; // Darker red for border

.rbc-event {
  background-color: $event-bg-color;
  border: 1px solid $event-border-color;
  color: #333;
  border-radius: 6px;
  padding: 6px;
  font-size: 12px;
  transition: all 0.2s ease;

  &:hover {
    background-color: $event-hover-color;
    border-color: darken($event-border-color, 10%);
    transform: scale(1.02);
  }

  &:focus {
    outline: none; // Remove default focus outline
  }
}

.rbc-event-label {
  color: darken(#333, 15%);
}

.rbc-event:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.rbc-event:active {
  transform: scale(1.05);
}

.rbc-event-not-set {
  background-color: $rbc-event-not-set-bg-color;
  border: 1px solid $rbc-event-not-set-border-color;
  color: #333; 
  border-radius: 6px; 
  padding: 6px;
  font-size: 12px;
  transition: all 0.2s ease;

  &:hover {
    background-color: lighten($rbc-event-not-set-bg-color, 10%);
    border-color: lighten($rbc-event-not-set-border-color, 10%);
    transform: scale(1.02);
  }

  &:focus {
    outline: none; // Remove default focus outline for not set events
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &:active {
    transform: scale(1.05);
  }
}

.resource-header {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.resource-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: #f9f9f9;
}

.resource-header {
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
}

.events-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
