body {
    background: #454a59;
  }
  
  .pulsating-circle {
    position: relative;
    width: 15px; 
    height: 15px; 
    margin: auto;
  }
    
  .pulsating-circle:before {
    content: '';
    position: absolute;
    left: -100%;
    top: -100%;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    border-radius: 22.5px; /* Half of 45px */
    background-color: white;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  .pulsating-circle:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: green;
    border-radius: 7.5px; /* Half of 15px */
    box-shadow: 0 0 4px rgba(0,0,0,.15); /* Half of previous shadow */
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
  
  
  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
  
  .call-display {
    font-size: 20px;
    line-height: 30px; /* same as the circle's height */
    color: white;
  }