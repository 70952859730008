.table-striped{
  background: #fff;
  border-radius: 10px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f7f7;
}

.table thead th,.table-bordered td{
  padding: 15px !important;
}
.table-hover tbody tr:hover {
  color: #464E5F;
  background-color: #E5EAEE !important;
}
.MuiButton-contained {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}